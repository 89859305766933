<script setup>
import { Head, Link } from '@inertiajs/vue3';
import { inject } from 'vue';

let route = inject('route');

defineProps({
    title: String,
});
</script>

<template>
    <div>
        <Head :title="title" />

        <div class="lex force-scroll flex-col bg-gray-medium">
            <!-- Page Heading -->
            <header class="h-[320px] w-full bg-blue">
                <div class="container flex-center min-h-20">
                    <Link :href="route('login')">
                        <img
                            alt="Flexable logo"
                            class="my-6"
                            src="@/../img/flexable-logo-sunset.svg"
                        >
                    </Link>
                </div>
            </header>

            <!-- Page Content -->
            <main>
                <slot />
            </main>
        </div>
    </div>
</template>
